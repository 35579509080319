import app from 'flarum/app'

import SettingsPage from 'flarum/components/SettingsPage'
import ItemList from 'flarum/utils/ItemList'
import Button from 'flarum/components/Button'
// import Switch from 'flarum/components/Switch'
import FieldSet from 'flarum/components/FieldSet'
// import ChangePasswordModal from 'flarum/components/ChangePasswordModal'
// import ChangeEmailModal from 'flarum/components/ChangeEmailModal'
import GdprAccessModal from './GdprAccessModal'
import GdprCleanModal from './GdprCleanModal'

import LogInModal from 'flarum/components/LogInModal'
import LogInButtons from 'flarum/components/LogInButtons'
import extractText from 'flarum/utils/extractText'
import SignUpModal from 'flarum/components/SignUpModal'
// import Alert from 'flarum/components/Alert'
// import GdprPrivacyAlert from './GdprPrivacyAlert'

/*
export  default function username(user) {
  const name = (user && user.username()) || app.translator.trans('core.lib.username.deleted_text');

  return <span className="username">xxxx{name}xxxx</span>;
}
*/

app.initializers.add('atum/gdpr', () => {
  //console.log('Hello, FORUM gdpr!')

  /**
   * UserSettings -> Add buttons for export and clean data
   */

  SettingsPage.prototype.settingsItems = function () {
    const items = new ItemList()

    items.add('account',
      FieldSet.component({
        label: app.translator.trans('core.forum.settings.account_heading'),
        className: 'Settings-account',
        children: this.accountItems().toArray()
      })
    )

    items.add('notifications',
      FieldSet.component({
        label: app.translator.trans('core.forum.settings.notifications_heading'),
        className: 'Settings-notifications',
        children: this.notificationsItems().toArray()
      })
    )

    items.add('privacy',
      FieldSet.component({
        label: app.translator.trans('core.forum.settings.privacy_heading'),
        className: 'Settings-privacy',
        children: this.privacyItems().toArray()
      })
    )

    items.add('gdpr',
      FieldSet.component({
        label: '',
        className: 'Settings-account',
        children: this.gdprItems().toArray()
      })
    )

    return items
  }

  SettingsPage.prototype.gdprItems = function () {
  	const items = new ItemList()

    items.add('gdprAccess',
      Button.component({
        // children: app.translator.trans('core.forum.settings.change_email_button'),
        children: 'Access to my personal data',
        className: 'Button',
        icon: 'street-view',
        onclick: () => app.modal.show(new GdprAccessModal())
        // onclick: () => console.log("modal1")
      })
    )

    items.add('gdprForgot',
      Button.component({
        children: 'Forgot my personal data & delete my account',
        className: 'Button',
        // icon: 'user-slash',
        icon: 'user-times',
        style: 'background-color: rgb(183, 42, 42);color:#E0E0E0',
        // onclick: () => app.modal.show(new ChangeEmailModal())
        onclick: () => app.modal.show(new GdprCleanModal())
      })
    )

    return items
  }

  /**
   * LogInModal -> Read and confirm privacy
   */

  LogInModal.prototype.init = function () {
    // super.init();

    /**
     * The value of the identification input.
     *
     * @type {Function}
     */
    this.identification = m.prop(this.props.identification || '')

    /**
     * The value of the password input.
     *
     * @type {Function}
     */
    this.password = m.prop(this.props.password || '')

    /**
     * The value of the remember me input.
     *
     * @type {Function}
     */
    this.remember = m.prop(!!this.props.remember)

    /**
     * The value of the acceptedPrivacy input.
     *
     * @type {Function}
     */
    this.acceptedPrivacy = m.prop(!!this.props.acceptedPrivacy)

    /**
       * Show or not the privacy alert
       *
       * @type {Boolean}
       */
    this.privacyAlert = false
  }

  LogInModal.prototype.onsubmit = function (e) {
    e.preventDefault()

    this.loading = true

    const identification = this.identification()
    const password = this.password()
    const remember = this.remember()
    var acceptedPrivacy = this.acceptedPrivacy()

    //console.log(identification)
    //console.log(password)
    //console.log(acceptedPrivacy)
    //console.log(remember)

    if (acceptedPrivacy) {
      this.privacyAlert = false
      //console.log('acceptedPrivacy true')
      app.session.login({identification, password, remember}, {errorHandler: this.onerror.bind(this)})
        .then(
          () => window.location.reload(),
          this.loaded.bind(this)
        )
    } else {
      this.privacyAlert = true
      //console.log('acceptedPrivacy false')
      this.loading = false
    }
  }

  LogInModal.prototype.content = function () {
    var acceptedPrivacy = false
    return [
      <div className='Modal-body'><LogInButtons />
        {this.privacyAlert === false ? '' : m('div', {className: 'Alert Alert--Error', style: 'margin-bottom: 10px;'}, 'You must read and agree the data protection terms')}
        <div className='Form Form--centered'>
          <div className='Form-group'>
            <input className='FormControl' name='identification' type='text' placeholder={extractText(app.translator.trans('core.forum.log_in.username_or_email_placeholder'))}
              bidi={this.identification}
              disabled={this.loading} />
          </div>

          <div className='Form-group'>
            <input className='FormControl' name='password' type='password' placeholder={extractText(app.translator.trans('core.forum.log_in.password_placeholder'))}
              bidi={this.password}
              disabled={this.loading} />
          </div>

          <div className='Form-group'>
            <div>
              <label className='checkbox'>
                <input type='checkbox' bidi={this.acceptedPrivacy} disabled={this.loading} />
                <span style={this.errorStyle}>I have read and agree with the <a href='https://www.stockmanagementlabs.com/privacy-policy/' target='_blank' title='data protection terms'>data protection terms</a>*</span>
              </label>
            </div>
          </div>

          <div className='Form-group'>
            <div>
              <label className='checkbox'>
                <input type='checkbox' bidi={this.remember} disabled={this.loading} />
                {app.translator.trans('core.forum.log_in.remember_me_label')}
              </label>
            </div>
          </div>

          <div className='Form-group'>
            {Button.component({
              className: 'Button Button--primary Button--block',
              type: 'submit',
              loading: this.loading,
              children: app.translator.trans('core.forum.log_in.submit_button')
            })}
          </div>
        </div>
      </div>,
      <div className='Modal-footer'>
        <p className='LogInModal-forgotPassword'>
          <a onclick={this.forgotPassword.bind(this)}>{app.translator.trans('core.forum.log_in.forgot_password_link')}</a>
        </p>

        {app.forum.attribute('allowSignUp') ? (
          <p className='LogInModal-signUp'>
            {app.translator.trans('core.forum.log_in.sign_up_text', {a: <a onclick={this.signUp.bind(this)} />})}
          </p>
        ) : ''}
      </div>
    ]
  }

  /**
   * SignUpModal -> Read and confirm privacy
   */
  SignUpModal.prototype.init = function () {
    // super.init();

    /**
     * The value of the username input.
     *
     * @type {Function}
     */
    this.username = m.prop(this.props.username || '')

    /**
     * The value of the email input.
     *
     * @type {Function}
     */
    this.email = m.prop(this.props.email || '')

    /**
     * The value of the password input.
     *
     * @type {Function}
     */
    this.password = m.prop(this.props.password || '')

    /**
     * The value of the acceptedPrivacy input.
     *
     * @type {Function}
     */
    this.acceptedPrivacy = m.prop(!!this.props.acceptedPrivacy)

    /**
       * Show or not the privacy alert
       *
       * @type {Boolean}
       */
    this.privacyAlert = false
  }

  SignUpModal.prototype.body = function () {
    //console.log(this.privacyAlert)
    var acceptedPrivacy = false

    return [
      this.props.token ? '' : <LogInButtons />,
      this.privacyAlert === false ? '' : m('div', {className: 'Alert Alert--Error', style: 'margin-bottom: 10px;'}, 'You must read and agree the data protection terms'),
      <div className='Form Form--centered'>
        <div className='Form-group'>
          <input className='FormControl' name='username' type='text' placeholder={extractText(app.translator.trans('core.forum.sign_up.username_placeholder'))}
            value={this.username()}
            onchange={m.withAttr('value', this.username)}
            disabled={this.loading} />
        </div>

        <div className='Form-group'>
          <input className='FormControl' name='email' type='email' placeholder={extractText(app.translator.trans('core.forum.sign_up.email_placeholder'))}
            value={this.email()}
            onchange={m.withAttr('value', this.email)}
            disabled={this.loading || (this.props.token && this.props.email)} />
        </div>

        {this.props.token ? '' : (
          <div className='Form-group'>
            <input className='FormControl' name='password' type='password' placeholder={extractText(app.translator.trans('core.forum.sign_up.password_placeholder'))}
              value={this.password()}
              onchange={m.withAttr('value', this.password)}
              disabled={this.loading} />
          </div>
        )}

        <div className='Form-group'>
          <div>
            <label className='checkbox'>
              <input type='checkbox' name='acceptedPrivacy'
                value={this.acceptedPrivacy()} onchange={m.withAttr('value', this.acceptedPrivacy)} disabled={this.loading || this.submitted} />
              <span style={this.errorStyle}>I have read and agree with the <a href='https://www.stockmanagementlabs.com/privacy-policy/' target='_blank' title='data protection terms' style={this.errorStyle}>data protection terms</a>*</span>
            </label>
          </div>
        </div>

        <div className='Form-group'>
          <Button
            className='Button Button--primary Button--block'
            type='submit'
            loading={this.loading}>
            {app.translator.trans('core.forum.sign_up.submit_button')}
          </Button>
        </div>
      </div>
    ]
  }

  SignUpModal.prototype.onsubmit = function (e) {
    e.preventDefault()

    this.loading = true
    
    var acceptedPrivacy = this.acceptedPrivacy()
    //console.log(acceptedPrivacy)

    if (acceptedPrivacy) {
      this.submitted = true
      this.privacyAlert = false
      //console.log('acceptedPrivacy true')

      const data = this.submitData()
      
      app.request({
        url: app.forum.attribute('baseUrl') + '/register',
        method: 'POST',
        data,
        errorHandler: this.onerror.bind(this)
      }).then(
        () => window.location.reload(),
        this.loaded.bind(this)
      )
    } else {
      //console.log('acceptedPrivacy false')
      this.loading = false
      this.errorStyle = 'color:red;'
      this.privacyAlert = true
    }
  }
}) // initializers()
