import {extend} from 'flarum/extend';
import CommentPost from 'flarum/components/CommentPost';
import Group from 'flarum/models/Group';

app.initializers.add('atum/signature', () => {
  //console.log('Hello, atum signature!')
  
  extend(CommentPost.prototype, 'view', function (vdom) {
    const user = this.props.post.user();
    //if user is admin load AtumSignature!
    if (user.groups().some(group => group.id() === Group.ADMINISTRATOR_ID)) {

      let atumSignature = `
        <img src="https://forum.stockmanagementlabs.com/assets/atum-logo-support.svg" alt=""><br>
        <p>Here to help you in everything you need!</p><br>
        <p><a href="https://wordpress.org/support/plugin/atum-stock-manager-for-woocommerce/reviews/?filter=5#new-post" title="ATUM Inventory - 5 stars rating" target="blank">HELP US TO IMPROVE!</a>
        If you like ATUM, please leave us a <a href="https://wordpress.org/support/plugin/atum-stock-manager-for-woocommerce/reviews/?filter=5#new-post" title="ATUM Inventory 5 stars rating" target="blank">★★★★★</a> rating. Huge thanks in advance!</p>
        `;
      vdom.children.push(m('div.SignatureWrapper.signature-bg', m.trust(atumSignature)));
    }else{
      //load user signature (if he has one)
      const Signature = this.props.post.user().data.attributes.signature || false

      if (Signature) {
        vdom.children.push(m('div.SignatureWrapper', m.trust(Signature)));
      }
    }
  });

}) // initializers()

