import Modal from 'flarum/components/Modal'
import Alert from 'flarum/components/Alert'
import Button from 'flarum/components/Button'
import ItemList from 'flarum/utils/ItemList'
import listItems from 'flarum/helpers/listItems'
import avatar from 'flarum/helpers/avatar'
import username from 'flarum/helpers/username'

/**
 * The `GdprAccessModal` component shows a modal dialog with the stored personal data.
 */
export default class GdprCleanModal extends Modal {
  init () {
    super.init()

    /**
     * Whether or not the email has been changed successfully.
     *
     * @type {Boolean}
     */
    this.success = false
    this.user = null
 	}// init

  className () {
   	 	return 'GdprCleanModal Modal--small'
  }
  title () {
    return 'Forgot my personal data & delete account'
    // return app.translator.trans('core.forum.change_email.title');
  }
  
  getItemList () {
  	const items = new ItemList()

    items.add('gdprAccess',
      Button.component({
        // children: app.translator.trans('core.forum.settings.change_email_button'),
        children: 'Name',
        className: 'Button'
        // onclick: () => console.log("modal1")
      })
    )

    return items
  }
  
  doCleanPost () {

    this._respuesta = app.request({
      url: app.forum.attribute('baseUrl') + '/api/gdpr/clean',
      method: 'POST',
      data: {},
      errorHandler: this.onerror.bind(this)
      //errorHandler: function(result) { console.log(result) }
    }).then(
      function() {
        alert('We hope to see you again.')
        app.session.logout()
      },
      function() {
        //console.log(this)
        app.session.logout()
      }
    )
    
  }

  getCleanDialogButtons () {
    const items = new ItemList()

    items.add('gdprCleanButton',
      Button.component({
        className: 'Button Button--danger Button--block',
        // type: 'button',
        loading: this.loading,
        children: 'Clean all my data and delete account',
        onclick: () => this.doCleanPost()
        // onclick: () => app.session.logout()
      })
    )
    items.add('gdprCleanButton2',
      Button.component({
        className: 'Button Button--primary Button--block',
        type: 'submit',
        loading: this.loading,
        children: 'close'
        // onclick: () => this.doExportPost()
        // onclick: () => alert("close")
      })
    )

    return items
  }

  content () {
  	this.user = app.session.user
  	// console.log('ready to clean')

    return (
      <div className='Modal-body'>
        <fieldset>
          <legend>Important </legend>
          <div class='helpText'>Hi {this.user.username()}, you are going to erase all your personal information stored on this forum. This action cannot be undone. Are you agree?</div>
        </fieldset>

        <div className='SettingsPage'>
          <ul>{listItems(this.getCleanDialogButtons().toArray())}</ul>
        </div>
      </div>
    )
  }

  onsubmit (e) {
    // console.log('onsubmit')
    e.preventDefault()
    // console.log(app.session)
    this.hide()
  }
}
