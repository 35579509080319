import Modal from 'flarum/components/Modal'
import Alert from 'flarum/components/Alert'
import Button from 'flarum/components/Button'
import ItemList from 'flarum/utils/ItemList'
import listItems from 'flarum/helpers/listItems'
import avatar from 'flarum/helpers/avatar'
import username from 'flarum/helpers/username'

/**
 * The `GdprAccessModal` component shows a modal dialog with the stored personal data.
 */
export default class GdprAccessModal extends Modal {
  init () {
    super.init()

    /**
     * Whether or not the email has been changed successfully.
     *
     * @type {Boolean}
     */
    this.success = false
    this.user = null
 	}// init

  className () {
   	 	return 'GdprAccessModal Modal--small'
  }
  title () {
    return 'Personal data'
    // return app.translator.trans('core.forum.change_email.title');
  }

  getItemList () {
  	const items = new ItemList()

    items.add('gdprAccess',
      Button.component({
        // children: app.translator.trans('core.forum.settings.change_email_button'),
        children: 'Name',
        className: 'Button'
        // onclick: () => console.log("modal1")
      })
    )

    return items
  }

  doExportPost () {
    //console.log('export that')
    app.request({
      url: app.forum.attribute('baseUrl') + '/api/gdpr/export',
      method: 'POST',
      data: {},
      errorHandler: this.onerror.bind(this)
    }).then(
      () =>
        //console.log('exported'),
      alert('You could find your data in your email in few minutes'),
      // window.location.reload(),
      this.loaded.bind(this)
    )
  }

  getExportDialogButtons () {
    const items = new ItemList()

    items.add('gdprExportButton',
      Button.component({
        className: 'Button Button--primary Button--block',
        type: 'button',
        loading: this.loading,
        children: 'Export data to my email',
        onclick: () => this.doExportPost()
      })
    )
    items.add('gdprCloseButton',
      Button.component({
        className: 'Button Button--primary Button--block',
        type: 'submit',
        loading: this.loading,
        children: 'close'
      })
    )

    return items
  }

  content () {
  	this.user = app.session.user
  	//console.log(this.user)

    return (
      <div className='Modal-body'>
        <fieldset>
          <legend>Username</legend>
          <div class='helpText'>{this.user.username()}</div>
        </fieldset>
        <fieldset>
          <legend>Email</legend>
          <div class='helpText'>{this.user.email()}</div>
        </fieldset>
        <fieldset>
          <legend>avatar</legend>
          <div class='helpText'>{avatar(this.user)}</div>
        </fieldset>

        <fieldset>
          <legend>Posts Count</legend>
          <div class='helpText'>
            <a href={app.route('user.posts', {username: this.user.username()})}>
              {this.user.data.attributes.commentCount}</a>
          </div>
        </fieldset>
        <fieldset>
          <legend>Discussion Count</legend>
          <div class='helpText'>
            <a href={app.route('user.discussions', {username: this.user.username()})}>
              {this.user.data.attributes.discussionCount}</a>
          </div>
        </fieldset>

        <div className='SettingsPage'>
          <ul>{listItems(this.getExportDialogButtons().toArray())}</ul>
        </div>
      </div>
    )
  }

  onsubmit (e) {
    e.preventDefault()
    //console.log('Submit')
    this.hide()
  }
}
